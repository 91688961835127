// ** Redux Imports
import { createSlice } from "@reduxjs/toolkit";

export const availableSlice = createSlice({
  name: "providerAvailable",
  initialState: {
    providerAvailable:
    localStorage.getItem("providerAvailable") !== "undefined"
    ? localStorage.getItem("providerAvailable") === "true"
    : false
  },
  reducers: {
    setProviderAvailable: (state, action) => {
      state.providerAvailable = action.payload;
      localStorage.setItem("providerAvailable", JSON.stringify(action.payload));
    },
  },
});

export const { setProviderAvailable } = availableSlice.actions;

export default availableSlice.reducer;
